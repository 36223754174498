.footer {
  background-color: #000;
  color: #fff;
  text-align: left;
  padding: 0rem 4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-topRow {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
}

.footer-bottomRow {
  /* display: flex; */
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: space-between;
  font-size: 1.1rem;
}

h1 {
  font-size: 10rem;
}
.footerText{
  font-size: 1.5rem;
}
.logoFooterDiv {
  width: 80px;
  height: 80px;
  background-image: url('../../images/MartinResults-M_Logo_Rev.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



@media (min-width: 1024px) {
  .footer {
    /* height: 80vh; */
  }
}

@media (max-width: 768px) {
  .footer {
    background-color: #000;
    color: #fff;
    text-align: left;
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .footer-bottomRow{
    display: flex;
    flex-direction: column;
  } 
  .footer-section{
    flex-direction: column;
  }
  .logoFooterDiv {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  h1 {
    font-size: 4rem;
  }
 .smallScreenLogoFooterDiv{
  display: flex;
  width: 50px;
  height: 50px;
  background-image: url('../../images/MartinResults-M_Logo_Rev.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: flex-end;
 }
  .footer-topRow {
    flex-direction: column;
    margin-bottom: 5rem;
    align-items: flex-start;
  }
}