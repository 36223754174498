.career-listing {
    display: flex;
    flex-direction: column;
    color:white;
}

.career-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    color: inherit;
    text-decoration: none;
}

.career-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
}

.career-arrow{
    color:  white !important;
}
.career-location {
    font-size: 2rem;
}

.career-arrow {
    content: '>';
    padding-left: 10px;
    font-size: 2rem;
    font-weight: bold;
    color: black;
}