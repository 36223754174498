.CP-Background {
    padding-top: 8vh;
    display: flex;
    background-color: #1C75BC;
    /* background-image: url('../../images/Red Cad_MA site copy.jpg'); */
    background-size: cover;
    background-position: center;
    flex-direction: column;
    justify-content: space-between;
}

.CP-bannerImage{
    background-image: url('../../images/careerBanner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    width: 100%;
    margin-top: 1rem;
}
.CP-mainContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 5rem;
    padding-right: 5rem;
    
}

.CP-MC-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.CP-MC-bottom {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.CP-OpenRoles {
    font-size: 3rem;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.CP-Message {
    font-size: 2rem;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* width: 65%; */
}


.topRow {
    font-size: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
    margin-left: 5rem;
    margin-right: 8rem;
}

.bottomRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
    margin-left: 5rem;
    margin-right: 8rem;
}

.logoCareerDiv {
    width: 80px;
    height: 80px;
    background-image: url('../../images/MartinResults-M_Logo_Rev.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.messageDiv {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .fullScreen {
        justify-content: flex-end;
        text-align: center;
        padding-bottom: 1rem;
    }

    .bottomRow {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        margin: 1.5rem;
    }

    .logoCareerDiv {
        width: 60px;
        height: 60px;
        margin-bottom: 2rem;
        margin-left: 0.5rem;
        order: 2;
        position: absolute;
        bottom: 0;
    }
    .CP-mainContent{
        flex-direction: column;
        padding: 1rem;
    }
    .messageDiv {
        order: 1;
        font-size: 1.5rem;
        width: 100%;
        margin-bottom: 1rem;
    }
}