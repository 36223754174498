.careerDetailsPage{
    display: flex;
    flex-direction: column;
    padding: 4rem 5rem;
    /* margin-top: 4rem; */
    background-color: #E83B13;
    color:white;
}

.careerDescription{
    font-size: 2rem;
}

.cpListItems {
    font-size: 1.5rem;
}

.cpBottomHalf {
    display: flex;

}

.cpBottomDetails {
    display: flex;
    flex-direction: column;
}

.cpBottomApply {
    display: flex;
}

.AP-button-style {
    display: inline-block; 
    width: 4rem;
    padding: 10px 20px; 
    margin: 5px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    background-color: #f7f7f7; 
    color: #333; 
    text-align: center; 
    cursor: pointer;
    text-decoration: none; 
    font-size: 16px; 
    transition: background-color 0.3s, color 0.3s; 
  }
  
  .AP-button-style:hover {
    background-color: #e7e7e7; 
    color: #000; 
  }
  

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .cpListItems {
      font-size: 1.75rem; /* Smaller font size on smaller screens */
    }
    .careerDescription{
        font-size: 1rem;
    }
  }
  
  /* Responsive adjustments for very small screens */
  @media (max-width: 480px) {
    .cpListItems {
      font-size: 1rem; /* Even smaller font size on very small screens */
    }
    .careerDescription{
        font-size: 1rem;
    }
    .careerDetailsPage{
      padding: 4rem 2rem;
      
  }
  
  }
  