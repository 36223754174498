.ApplyModal {
  color: black;
  font-size: 1.4rem;
}

.ApplyModal button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #333;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.ApplyModal input{
  font-size: 1.4rem;
  border: none;
  padding-left: .5rem;
}

.ApplyModal textarea {
  color: black;
}

.ApplyModal textarea::placeholder {
  color: gray;
}

.ApplyModal button:hover {
  background-color: #e7e7e7;
  color: #000;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .ApplyModal {
    width: 80%;
    /* Make the modal wider on smaller screens */
    max-width: none;
    /* Allow it to grow up to 80% of the viewport width */
  }
}