.client-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  color: white;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  text-shadow: 2px 2px 4px #000000; /* X-offset, Y-offset, Blur radius, Color */
  
}

.client-section h1 {
  margin-bottom: 2rem;
  font-size: 4rem;

}

.clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.client-name {
  cursor: pointer;
  margin: 0.5rem 0;
  font-size: 3rem;
  transition: color 0.3s ease-in-out;
}

.client-name:hover {
  color: #cccccc;
}