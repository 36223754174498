.header {
    display: flex; /* Use flexbox layout */
    justify-content: flex-start; /* Align the items to the left */
    align-items: center; /* Center the items vertically */
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vh; /* Set the height to be 10% of the viewport height */
    padding: 0 5rem; /* Add horizontal padding */
    background-color: #fff; /* White background */
    color: #000; /* Text color */
    font-size: 3rem; /* Adjust the font size as needed */
    font-weight: bold; /* Makes the font bold */
    box-shadow: 0 2px 8px rgba(0,0,0,0.1); /* Optional: Adds a shadow for better visibility */
    z-index: 1000; /* Ensures the header is above other content */
    transition: all 0.275s ease-in-out; /* Smooth transition for any changes */
  }
  
  .header.top {
    background-color: transparent; /* Transparent background at the top */
    color: #fff; /* White text for the transparent background */
    box-shadow: none; /* Optional: Adds a shadow for better visibility */

  }
  
  .header.hidden {
    top: -100%; /* Hide the header */
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 0 2rem; /* Smaller padding on smaller screens */
      font-size: 2rem; /* Smaller font size on smaller screens */
    }
  }
  