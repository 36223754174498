/* Component.css */
.component {
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Center the items vertically */
  background-color: #1C75BC!important; /* Blue background */
  flex-direction: column;
  text-align: left; /* Align text to the left */
  color: #ffffff; /* White text */
  font-size: 2.5rem; /* Large, easy to read font size */
  text-align: center; /* Center align the text */
  padding: 6.5vh 10vw; /* Adaptive padding based on the viewport size */
  /* margin: auto;  */
  height: 150%; 
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-sizing: border-box; /* Padding included in the width and height */
  transition: padding 0.3s ease-in-out; /* Smooth transition for padding changes */
}
.component p {
  text-align: left; /* Align text to the left */
  width:80%;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .component {
    font-size: 1.75rem; /* Smaller font size on smaller screens */
    padding: 4vh 5vw; /* Smaller padding on smaller screens */
  }
}

/* Responsive adjustments for very small screens */
@media (max-width: 480px) {
  .component {
    font-size: 2rem; /* Even smaller font size on very small screens */
    padding: 4vh 3vw; /* Even smaller padding on very small screens */
  }
}
