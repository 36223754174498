.HeroScreenfullScreen {
    height: 100vh;
    /* width: 100vw; */
    display: flex;
    background-image: url('../../images/Red Cad_MA site copy.jpg');
    background-size: cover;
    background-position: center;
    flex-direction: column;
    justify-content: flex-end;
}


.heroBottomRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem;
    margin-left: 5rem;
    margin-right: 8rem;
}

.heroLogoDiv {
    width: 80px;
    height: 80px;
    background-image: url('../../images/MartinResults-M_Logo_Rev.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.heroMessageDiv {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .HeroScreenfullScreen {
        justify-content: flex-end;
        text-align: center;
        padding-bottom: 1rem;
    }

    .heroBottomRow {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        margin: 1.5rem;
    }

    .heroLogoDiv {
        width: 60px;
        height: 60px;
        margin-bottom: 2rem;
        margin-left: 0.5rem;
        order: 2;
        position: absolute;
        bottom: 0;
    }

    .heroMessageDiv {
        order: 1;
        font-size: 1.5rem;
        width: 100%;
        margin-bottom: 1rem;
    }
}