/* HistorySection.css */
.history-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #E83B13 !important;

}

.upper-section {
  color: white;
  padding: 2rem;
  text-align: left;
  margin-left: 5%;
  width: 65%;
  font-size: 7rem;
  margin-bottom: 5rem;;
}

.lower-section {
  color: white;
  margin-left: 5%;
  padding: 2rem;
  border-left: 3px solid white;
  font-size: 2.5rem;
  align-content: space-around;
  margin-bottom: 2.5%

}
.nameDiv{
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 75%;
}

.lowerSectionLogo {
  width: 50px;
  height: 50px;
  background-image: url('../../images/MartinResults-M_Logo_Rev.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
  .upper-section{
    font-size: 4rem;
  }

  .upper-section,
  .lower-section {
    padding: 1rem;
    border-left: none;
  }
  .textDiv{
    width: 100%;
  }
  .lower-section {
    border-bottom: 5px solid white;
    margin-right: 5%;
    font-size: 1.5rem;
    margin-bottom: 10%
  }
}