.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  background: #FFD700; 
  padding-left: 5rem; 
  padding-bottom: 5rem; 
  padding-right: 5rem; 
  padding-top: 5rem;
  flex-direction: column;
}

.service-title {
  font-size: 4rem; 
  margin-bottom: 20px; 
}

.services-section {
  display: flex;
  flex-direction: row;
  gap:20px
}
.service-column {
  flex: 1; 
  min-width: 250px; 
  font-size: 3rem;
}

.service-item {
  border-bottom: 3px solid black; 
  padding: 1.5rem 0rem; 
  font-weight: 550;
}

@media (max-width: 768px) {
  .service-column {
    flex-basis: 100%;
  }
  .services-section{
    flex-direction: column;
    gap:0px;
  }
  .services-container{
    padding:1rem;
  }
}
